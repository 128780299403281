import { BlockCategory } from './block-category.constants';

export class BlockCategoryWrapper {
    static readonly INPUT = new BlockCategoryWrapper(
        BlockCategory.Input,
        'Input',
        'blue',
        `<svg
        transform="rotate(180)"
        :class="{ 'text-white': activeFilter === 'input' }"
        class="w-6 h-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
        ></path>
    </svg>`,
    );

    static readonly PREPARATION = new BlockCategoryWrapper(
        BlockCategory.Prep,
        'Preparation',
        'green',
        '<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" > <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" ></path> </svg>',
    );

    static readonly ML = new BlockCategoryWrapper(
        BlockCategory.MachineLearning,
        'Machine Learning',
        'orange',
        '<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" > <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z" ></path> </svg>',
        false,
    );

    static readonly OUTPUT = new BlockCategoryWrapper(
        BlockCategory.Output,
        'Output',
        'purple',
        '<svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" > <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" ></path> </svg>',
    );

    private constructor(
        public readonly category: BlockCategory,
        public readonly label: string,
        public readonly colour: string,
        public readonly iconHtml: string,
        public readonly canDryRun: boolean = true,
    ) {
        this.category = category;
        this.label = label;
        this.colour = colour;
        this.iconHtml = iconHtml;
        this.canDryRun = canDryRun;
    }

    static all(): BlockCategoryWrapper[] {
        return [this.INPUT, this.PREPARATION, this.ML, this.OUTPUT];
    }

    static find(category: string): BlockCategoryWrapper | null {
        for (let b = 0; b < BlockCategoryWrapper.all().length; b++) {
            const categoryWrapper = BlockCategoryWrapper.all()[b];
            if (categoryWrapper.category === category) {
                return categoryWrapper;
            }
        }
        return null;
    }
}
