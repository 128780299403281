export enum WarningMessages {
    DRY_RUN = 'Sample data are missing. You should perform a sample run first.',
    NO_TEST_RUN = 'No sample data available yet. Sample run needs to be completed.',
    NOT_FINALISED = 'Please finalise your workflow first.',
    NO_EXECUTIONS = 'No executions available. Perform a dry run first.',
    DEFAULT = 'Please select an output block and visualization type.',
    NO_NORMAL_DATA = 'No data available. Perform a normal run first.',
    INVALID_CONFIGURATION = 'No preview available because of invalid configuration. Please make sure to provide a valid one.',
    DEPRECATED_WORKFLOW = 'Please select an output block to view its results and configuration. No other actions are available.',
}
