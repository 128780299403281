export class WorkflowSortingOption {
    static readonly TITLE_ASC = new WorkflowSortingOption([['name']], true, 'Title Ascending');

    static readonly TITLE_DESC = new WorkflowSortingOption([['name']], false, 'Title Descending');

    static readonly DATE_CREATED_ASC = new WorkflowSortingOption([['createdAt']], false, 'Date created newest first');

    static readonly DATE_CREATED_DESC = new WorkflowSortingOption([['createdAt']], true, 'Date created oldest first');

    static readonly DATE_UPDATED_ASC = new WorkflowSortingOption([['updatedAt']], false, 'Date updated newest first');

    static readonly DATE_UPDATED_DESC = new WorkflowSortingOption([['updatedAt']], true, 'Date updated oldest first');

    static readonly DATE_EXECUTED_ASC = new WorkflowSortingOption(
        [['executions', 0, 'createdAt']],
        false,
        'Date executed newest first',
    );

    static readonly DATE_EXECUTED_DESC = new WorkflowSortingOption(
        [['executions', 0, 'createdAt']],
        true,
        'Date executed oldest first',
    );

    static readonly STATUS_ASC = new WorkflowSortingOption([['statusPriority']], true, 'Status Ascending');

    static readonly STATUS_DESC = new WorkflowSortingOption([['statusPriority']], false, 'Status Descending');

    static readonly USER_ASC = new WorkflowSortingOption(
        [
            ['createdBy', 'firstName'],
            ['createdBy', 'lastName'],
        ],
        true,
        'User Ascending',
    );

    static readonly USER_DESC = new WorkflowSortingOption(
        [
            ['createdBy', 'firstName'],
            ['createdBy', 'lastName'],
        ],
        false,
        'User Descending',
    );

    private constructor(
        public readonly fieldPaths: any[][],
        public readonly asc: boolean,
        public readonly label: string,
    ) {
        this.fieldPaths = fieldPaths;
        this.asc = asc;
        this.label = label;
    }

    static all(): WorkflowSortingOption[] {
        return [
            this.TITLE_ASC,
            this.TITLE_DESC,
            this.DATE_CREATED_ASC,
            this.DATE_CREATED_DESC,
            this.DATE_UPDATED_ASC,
            this.DATE_UPDATED_DESC,
            this.DATE_EXECUTED_ASC,
            this.DATE_EXECUTED_DESC,
            this.STATUS_ASC,
            this.STATUS_DESC,
            this.USER_ASC,
            this.USER_DESC,
        ];
    }

    static find(fieldPaths: any[][], asc: boolean): WorkflowSortingOption | null {
        for (let i = 0; i < this.all().length; i++) {
            const sortOption = this.all()[i];
            if (JSON.stringify(sortOption.fieldPaths) === JSON.stringify(fieldPaths) && sortOption.asc === asc) {
                return sortOption;
            }
        }
        return null;
    }

    static findByLabel(label: string): WorkflowSortingOption | null {
        for (let i = 0; i < this.all().length; i++) {
            const sortOption = this.all()[i];
            if (sortOption.label === label) {
                return sortOption;
            }
        }
        return null;
    }
}
