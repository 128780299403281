import * as R from 'ramda';
import { Task } from '../types';
import { ExecutionStatus } from './execution-status.constants';
import { ExecutionType } from './execution-type.constants';

export class ExecutionTypeWrapper {
    static readonly DRY = new ExecutionTypeWrapper(
        ExecutionType.Dry,
        (task: Task) => (task.block.type === 'train' ? `` : `Structure of task ${task.displayName} has been evaluated`),
        (task: Task) =>
            task.block.type === 'train' ? `` : `Structure of task ${task.displayName} is currently under evalutation`,
        (task: Task) =>
            task.block.type === 'train' ? `` : `Structure of task ${task.displayName} is in queue to be evaluated`,
        (task: Task) =>
            task.block.type === 'train'
                ? ``
                : `Evaluation of structure for task ${task.displayName} has been cancelled`,
        (task: Task) =>
            task.block.type === 'train' ? `` : `Evaluation of structure for task ${task.displayName} has failed`,
    );

    static readonly TEST = new ExecutionTypeWrapper(
        ExecutionType.Test,
        (task: Task) =>
            task.block.type === 'train'
                ? `Training (fitting) of ${task.displayName} is completed`
                : `Sample data from executing workflow up to task ${task.displayName} has been calculated`,
        (task: Task) =>
            task.block.type === 'train'
                ? `Training (fitting) of ${task.displayName} is in progress`
                : `Sample data from executing workflow up to task ${task.displayName} is currently being calculated`,
        (task: Task) =>
            task.block.type === 'train'
                ? `Training (fitting) of ${task.displayName} is in queue to be performed`
                : `Sample data from executing workflow up to task ${task.displayName} is in queue to be calculated`,
        (task: Task) =>
            task.block.type === 'train'
                ? `Training (fitting) of ${task.displayName} has been cancelled`
                : `Evaluation of sample data from executing workflow up to task ${task.displayName} has been cancelled`,
        (task: Task) =>
            task.block.type === 'train'
                ? `Training (fitting) of ${task.displayName} has failed`
                : `Evaluation of sample data from executing workflow up to task ${task.displayName} has failed`,
    );

    static readonly NORMAL = new ExecutionTypeWrapper(
        ExecutionType.Normal,
        () => `Run of workflow has completed`,
        () => `Run of workflow is currently in progress`,
        () => `Run of workflow is queued for execution`,
        () => `Run of workflow has been cancelled`,
        () => `Run of workflow has failed`,
    );

    private constructor(
        public readonly type: ExecutionType,
        private readonly completedFunc: any,
        private readonly runningFunc: any,
        private readonly queuedFunc: any,
        private readonly cancelledFunc: any,
        private readonly failedFunc: any,
    ) {
        this.type = type;
        this.completedFunc = completedFunc;
        this.runningFunc = runningFunc;
        this.queuedFunc = queuedFunc;
        this.cancelledFunc = cancelledFunc;
        this.failedFunc = failedFunc;
    }

    static all(): ExecutionTypeWrapper[] {
        return [this.DRY, this.TEST, this.NORMAL];
    }

    static find(type: ExecutionType | string): ExecutionTypeWrapper | null {
        for (let t = 0; t < ExecutionTypeWrapper.all().length; t++) {
            const typeWrapper = ExecutionTypeWrapper.all()[t];
            if (typeWrapper.type === type) {
                return typeWrapper;
            }
        }
        return null;
    }

    completed(task?: Task | null): string {
        return this.completedFunc(task);
    }

    running(task?: Task | null): string {
        return this.runningFunc(task);
    }

    queued(task?: Task | null): string {
        return this.queuedFunc(task);
    }

    cancelled(task?: Task | null): string {
        return this.cancelledFunc(task);
    }

    failed(task?: Task | null): string {
        return this.failedFunc(task);
    }

    message(status: ExecutionStatus, task?: Task | null, message?: string | null) {
        if (status === ExecutionStatus.Completed) {
            return { message: this.completed(task), category: 'Success' };
        }
        if (status === ExecutionStatus.Running) {
            return { message: this.running(task), category: 'Running' };
        }
        if (status === ExecutionStatus.Queued) {
            return { message: this.queued(task), category: 'Queued' };
        }
        if (status === ExecutionStatus.Cancelled) {
            return { message: this.cancelled(task), category: 'Cancelled' };
        }
        if (status === ExecutionStatus.Failed) {
            return {
                message: !R.isNil(message) ? `${this.failed(task)}: ${message}` : this.failed(task),
                category: 'Error',
            };
        }
        return { message: null, category: null };
    }
}
